<template>
    <projects />
    <cta-component />
</template>
<script>
    import CtaComponent from '@/components/cta-component.vue';
    import Projects from '@/components/projects/projects.vue';
    export default{
        components:{
            Projects,
            CtaComponent
        }
    }
</script>@/components/projects components/projects.vue