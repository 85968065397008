<template>
    <section>
        <div class="flex flex-col justify-center items-center w-full mx-auto my-16">
            <h3 class="text-4xl text-[#012a62] font-bold uppercase mb-8">Localização</h3>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.5817595752214!2d-47.40250118824537!3d-22.369415818803965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c877f989814a41%3A0x7beec8fd34f62c93!2sZettatecck%20Projetos%20Industriais%20e%20Automa%C3%A7%C3%A3o!5e0!3m2!1spt-BR!2sbr!4v1716836551320!5m2!1spt-BR!2sbr&zoom=0" width="750" height="375" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="border-none rounded-2xl md:max-lg:w-8/12 xs:max-md:w-11/12"></iframe>
        </div>
    </section>
</template>
<script>
    export default{
        data(){
            return{
                
            }
        }
    }
</script>