<template>
    <section class="mb-16">
        <div class="flex justify-center items-center h-72 mb-8 border-[#a1dfe7] border-b banner-about
            lg:max-xl:h-56
            xs:max-lg:h-36">
            <div class="absolute text-6xl flex justify-center mb-6">
                <h2 class="text-6xl text-[#012a62] font-bold uppercase xs:max-md:text-4xl text-center">Sobre nós</h2>
            </div>
        </div>
        <div class="flex flex-col justify-center items-center mb-12">
            <h3 class="text-4xl text-center mb-6 text-[#012a62] font-bold uppercase xs:hidden">Bem-vindo à Zettatecck</h3>
            <h3 class="hidden text-4xl text-center mb-6 text-[#012a62] font-bold uppercase xs:block">Bem-vindo<br> à Zettatecck</h3>
            <p class="text-xl w-6/12 text-justify
            xs:max-xl:text-lg xs:max-2xl:w-10/12 xs:max-sm:leading-normal">
                Somos uma empresa especializada em tecnologia de alto nível. Trabalhamos no segmento de Engenharia Elétrica e Automação Industrial. 

                Buscamos agregar nossa experiência para ajudar no desenvolvimento e nas melhores soluções para nossos clientes. 

                Temos uma equipe altamente treinada e podemos lhe atender em todas as etapas do seu processo.
            </p>
            <p class="text-xl w-6/12 text-center mt-4
            xs:max-xl:text-lg xs:max-2xl:w-10/12 xs:max-sm:leading-normal">
                <b>Conheça um pouco mais da Zettatecck!</b><br> Estamos a disposição para ajudar você a conquistar seus objetivos.
            </p>
            
        </div>
        <div class="flex flex-col justify-center items-center mb-12">
            <h3 class="text-4xl text-[#012a62] font-bold uppercase mb-6">Quem somos</h3>
            <p class="text-xl text-justify w-6/12
            xs:max-xl:text-lg xs:max-2xl:w-10/12 xs:max-sm:leading-normal">
                Fundada em 2011 na cidade de Araras-SP, a ZETTATECCK PROJETOS INDUSTRIAIS E AUTOMAÇÃO LTDA é especializada na fabricação de painéis elétricos de baixa tensão, controle e instrumentação, robôs sinalizadores, e na elaboração de projetos elétricos industriais e automação predial. Buscamos inovar constantemente, desenvolvendo soluções que reduzem custos e aumentam a produtividade e a qualidade. Comprometemo-nos a mitigar riscos de segurança do trabalho, garantindo um ambiente mais seguro para todos.
            </p>
        </div>
    </section>
    <section class="my-16">
        <div class="flex flex-col justify-center items-center mb-6">
            <h3 class="text-4xl text-[#012a62] font-bold uppercase text-center mb-6 xs:hidden">Pilares da nossa empresa</h3>
            <h3 class="hidden text-4xl text-center text-[#012a62] font-bold uppercase mb-6 xs:block">Pilares<br>da nossa empresa</h3>
        </div>
        <!-- div que compreende as outras divs -->
        <div class="flex flex-row justify-evenly
        xs:max-xl:flex-wrap">
            <!-- div da missao -->
            <div class="flex justify-center items-center w-3/12
            xl:w-fit
            lg:max-xl:w-full lg:max-xl:mb-12
            xs:max-lg:w-full xs:max-lg:mb-8 xs:max-sm:mx-2">
                <div class="border w-full h-96 px-12 py-12 rounded-xl hover:shadow-2xl hover:scale-110 hover:-translate-y-1 duration-500
                xl:max-2xl:px-6 xl:w-10/12
                lg:max-xl:w-4/12
                sm:w-6/12
                xs:w-10/12 xs:max-md:px-4">
                <div class="flex justify-center w-full h-1/6">
                    <i class="fa-solid fa-flag text-4xl mb-4"></i>
                </div>
                    <div class="flex justify-center w-full h-1/6">
                        <h4 class="text-2xl">Missão</h4>
                    </div>
                    <div class="h-5/6">
                        <p class="text-justify text-base md:text-sm xs:max-md:text-sm">
                            Desenvolver projetos, equipamentos e serviços inovadores, combinando tecnologia e criatividade para garantir a satisfação contínua dos nossos clientes. Valorizamos nossos colaboradores e fornecedores, incentivando o aperfeiçoamento constante de suas habilidades, visando ao sucesso e à prosperidade da empresa, da comunidade e de cada indivíduo.    
                        </p>
                    </div>
                </div>
            </div>

            <!-- div da visao -->
            <div class="flex justify-center items-center w-3/12
            xl:w-fit
            lg:max-xl:w-4/12
            xs:max-lg:w-full xs:max-lg:mb-8 xs:max-sm:mx-2">
                <div class="border w-full h-96 px-12 py-12 rounded-xl hover:shadow-2xl hover:scale-110 hover:-translate-y-1 duration-500
                xl:max-2xl:px-6 xl:w-10/12
                lg:max-xl:w-full
                sm:w-6/12
                xs:w-10/12 xs:max-md:px-4">
                    <div class="flex justify-center w-full h-1/6">
                        <i class="fas fa-eye text-4xl"></i>
                    </div>
                    <div class="flex justify-center w-full h-1/6">
                        <h4 class="text-2xl">Visão</h4>
                    </div>
                    <div class="h-5/6">
                        <p class="text-justify text-base xs:max-md:text-sm">
                            Na ZETTATECCK, cultivamos a criatividade e a tecnologia em cada projeto, buscando sempre a satisfação dos nossos clientes. Acreditamos no crescimento contínuo e na prosperidade compartilhada da empresa e da comunidade.    
                        </p>
                    </div>
                </div>
            </div>

            <!-- div dos valores -->
            <div class="flex justify-center items-center w-3/12
            xl:w-fit
            lg:max-xl:w-4/12
            xs:max-lg:w-full xs:max-lg:mb-8">
                <div class="border w-full h-96 px-12 py-12 rounded-xl hover:shadow-2xl hover:scale-110 hover:-translate-y-1 duration-500
                xl:max-2xl:px-6 xl:w-10/12
                lg:max-xl:w-full
                sm:w-6/12
                xs:w-10/12 xs:max-md:px-4
                ">
                    <div class="flex justify-center w-full h-1/6">
                        <i class="fas fa-handshake text-4xl mb-4"></i>
                    </div>
                    <div class="flex justify-center w-full h-1/6">
                        <h4 class="text-2xl">Valores</h4>
                    </div>
                    <div class="h-5/6 xs:max-sm:w-full">
                        <p class="text-justify text-base xs:max-md:text-sm">
                            Produzimos equipamentos e serviços de alta qualidade, cumprindo prazos e custos planejados. Buscamos soluções tecnicamente viáveis e econômicas, aplicando sempre a melhor tecnologia, criatividade e flexibilidade.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default{
        data(){
            return{

            }
        }
    }
</script>
<style>
   .banner-about {
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='386' height='386' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23007B8A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2300AAC0'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    /* Adicionar um degradê de opacidade */
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)  
    ),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='386' height='386' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23007B8A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2300AAC0'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    
}

</style>