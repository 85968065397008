<template>
    <about />
</template>
<script>
    import About from '@/components/about/about.vue'
    export default{
        components:{
            About
        }
    }
</script>